<template>
  <div class="bottom-navigation zi-6">
    <v-bottom-navigation :height="height" background-color="primary" fixed>
      <div :style="{ transform: `translate3d(${glider.positionX}px, ${glider.positionY}px, 0)` }" class="glider"></div>
      <template v-for="({ to, icon, exact }, key) in menuItems">
        <v-btn :key="key" ref="glider-position" :to="to" :ripple="false" :exact="exact">
          <inline-svg :src="icon" width="29" />
        </v-btn>
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { useScreenSafeArea } from '@vueuse/core';

const screenSafeArea = useScreenSafeArea();

export default {
  components: {
    InlineSvg,
  },
  data() {
    return {
      glider: {
        positionList: [],
        positionX: 0,
        positionY: 25,
      },
      menuItems: [
        {
          to: { name: 'dashboard' },
          icon: require('@/modules/Layout/assets/icons/dashboard.svg'),
          exact: true,
        },
        {
          to: { name: 'analysis' },
          icon: require('@/modules/Layout/assets/icons/analysis.svg'),
          exact: false,
        },
        {
          to: { name: 'car' },
          icon: require('@/modules/Layout/assets/icons/car.svg'),
          exact: true,
        },
        {
          to: { name: 'settings' },
          icon: require('@/modules/Layout/assets/icons/settings.svg'),
          exact: true,
        },
        {
          to: { name: 'contact' },
          icon: require('@/modules/Layout/assets/icons/contact.svg'),
          exact: true,
        },
      ],
    };
  },
  computed: {
    height() {
      return 56 + parseInt(screenSafeArea.bottom.value);
    },
  },
  watch: {
    $route(to) {
      this.setGlider(to);
    },
  },
  mounted() {
    screenSafeArea.update();
    setTimeout(() => {
      this.setGliderPositionList();
      this.setGlider(this.$route);
    }, 100);
  },
  methods: {
    setGliderPositionList() {
      this.glider.positionList = this.$refs['glider-position'].map(
        (ref) =>
          ref.$el.getBoundingClientRect().left +
          ref.$el.getBoundingClientRect().width / 2 -
          document.body.clientWidth / 2
      );
    },
    setGlider(to) {
      const index = this.menuItems.findIndex((item) => to.name?.startsWith(item.to.name));
      if (index > -1) {
        this.glider.positionX = this.glider.positionList[index];
        this.glider.positionY = 0;
      } else {
        this.glider.positionY = 25;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.zi-6 {
  z-index: 6;
}
.bottom-navigation {
  position: relative;

  .glider {
    width: 150px;
    height: 25px;
    margin-left: -75px;
    background: url('@/modules/Layout/assets/glider-horizontal.svg') no-repeat center center;
    background-size: contain;
    position: absolute;
    top: -25px;
    left: 50%;
    z-index: -1;
    transition-duration: 0.5s;
    transition-property: transform;
  }

  .v-btn {
    min-width: 65px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: env(safe-area-inset-bottom);

    ::v-deep(.v-btn__content) {
      color: white !important;
      transition-duration: 0.5s;
      transition-property: color;
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      margin: 0;
      border-radius: 50%;
      background-color: var(--v-primary-base);
      opacity: 1 !important;
      top: calc(50% - (env(safe-area-inset-bottom) / 2));
      left: 50%;
      transition-duration: 0.5s;
      transition-property: width, height, margin, background-color;
    }

    &--active {
      transform: translateY(-10px);

      ::v-deep(.v-btn__content) {
        color: black !important;
      }

      &::before {
        width: 56px;
        height: 56px;
        margin: -28px;
        background-color: white;
      }
    }
  }
}
</style>
